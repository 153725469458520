import logo from 'assets/images/logo.png';
import './App.scss';

export const App: React.FC = () => {
  return (
    <div className="app">
      <img src={logo} className="app-logo" alt="logo" />
      <h2>Coming Soon</h2>
    </div>
  );
};
